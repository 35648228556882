import type { MenuItem } from '@shared/types'
import TruckIcon from '@shared/components/Icons/TruckIcon.vue'
import InfoIcon from '@shared/components/Icons/InfoIcon.vue'
import ShieldCheckIcon from '@shared/components/Icons/ShieldCheckIcon.vue'
import PhoneIcon from '@shared/components/Icons/PhoneIcon.vue'
import ArrowPathRouteIcon from '@shared/components/Icons/ArrowPathRouteIcon.vue'
import BriefcaseIcon from '@shared/components/Icons/BriefcaseIcon.vue'
import linkTo from '@shared/modules/linkTo'

export default (): MenuItem[] => {
    return [
        {
            label: 'main.contacts',
            href: linkTo('contact-us'),
            active: isActive('contact-us'),
            icon: PhoneIcon,
            className: 'inline-flex',
        },
        {
            label: 'main.delivery',
            href: linkTo('pages/delivery'),
            active: isActive('delivery'),
            icon: TruckIcon,
            className: 'inline-flex',
        },
        {
            label: 'main.about_us',
            href: linkTo('pages/about_us'),
            active: isActive('about_us'),
            icon: InfoIcon,
            className: 'inline-flex',
        },
        {
            label: 'main.guarantees',
            href: linkTo('pages/terms'),
            active: isActive('terms'),
            icon: ShieldCheckIcon,
            className: 'inline-flex',
        },
        {
            label: 'main.exchanges_returns',
            href: linkTo('pages/pravila-obminu-ta-povernennya'),
            active: isActive('pravila-obminu-ta-povernennya'),
            icon: ArrowPathRouteIcon,
            className: 'block lg:hidden 2xl:block',
        },
        {
            label: 'main.privacy_policy',
            href: linkTo('pages/politika-konfidencijnosti'),
            active: isActive('politika-konfidencijnosti'),
            icon: BriefcaseIcon,
            className: 'block lg:hidden 2xl:block',
        },
    ]
}

function isActive(path: string): boolean {
    return window.location.pathname.includes(path)
}
