<script setup lang="ts">
import type { Product } from '@shared/types/models'
import { ref, onMounted } from 'vue'
import { trans } from 'laravel-vue-i18n'
import axios from 'axios'
import handleError from '@/modules/handleError'
import ProductCard from '@/components/Products/ProductCard.vue'
import Spinner from '@shared/components/Spinner.vue'

const props = defineProps<{
    product: Product
    categorySlug: string
}>()

const products = ref<Product[]>([])
const loading = ref<boolean>(false)

onMounted(async () => {
    await fetchProducts()
})

async function fetchProducts(): Promise<void> {
    loading.value = true

    const url = `/api/products/${props.product.slug}/recommended`

    try {
        const resp = await axios.get<Product[]>(url)
        products.value = resp.data
    } catch (err) {
        handleError(err)
    }

    loading.value = false
}
</script>

<template>
    <div
        v-if="!loading && products.length > 0"
        class="mb-5 md:my-10 py-5 border-t border-border-light px-4"
    >
        <div class="max-w-product-page mx-auto">
            <h2 class="text-lg font-heading font-bold mb-5 uppercase">
                {{ trans('product.you_might_like') }}
            </h2>

            <spinner v-if="loading" />

            <div
                v-else
                class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-2 gap-y-4"
            >
                <product-card
                    v-for="product in products"
                    :key="product.id"
                    :product
                    :category-slug
                    :use-short-title="true"
                />
            </div>
        </div>
    </div>
</template>
