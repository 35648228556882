import type { CheckoutFormData } from '@shared/types'
import type { Area, City, Warehouse } from '@shared/types/novaPoshta'
import { defineStore } from 'pinia'
import { ref, onMounted } from 'vue'
import { trans } from 'laravel-vue-i18n'
import { useCartStore } from '@/stores/cart'
import handleError from '@/modules/handleError'
import showToast from '@shared/modules/showToast'
import axios from 'axios'

export const useCheckoutStore = defineStore('checkout', () => {
    const cartStore = useCartStore()

    const areas = ref<Area[]>([])
    const cities = ref<City[]>([])
    const warehouses = ref<Warehouse[]>([])
    const creating = ref<boolean>(false)

    const formData = ref<CheckoutFormData>({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        delivery: 'warehouse',
        payment: 'cod',
        comment: '',
        address: {
            area: null,
            city: null,
            warehouse: null,
            deliveryAddress: '',
        },
    })

    onMounted(fetchAreas)

    async function createOrder(): Promise<void> {
        if (creating.value) {
            return
        }

        creating.value = true

        try {
            const resp = await axios.post<string>('/api/orders', {
                first_name: formData.value.firstName,
                last_name: formData.value.lastName,
                phone: formData.value.phone,
                cart: cartStore.cartItems.map(item => ({
                    slug: item.slug,
                    quantity: item.quantity,
                })),
                delivery_method: formData.value.delivery,
                payment_method: formData.value.payment,
                email: formData.value.email || null,
                comment: formData.value.comment || null,
                delivery_area: formData.value.address.area?.description || null,
                delivery_city: formData.value.address.city?.description || null,
                delivery_warehouse:
                    formData.value.address.warehouse?.description || null,
                address: formData.value.address.deliveryAddress || null,
            })

            showToast({ text: trans('main.order_created') })
            cartStore.clear()

            let toPage = `/order-status?order=${resp.data}`

            if (formData.value.payment === 'online') {
                toPage += '&online-payment'
            }

            window.location.href = toPage
        } catch (e) {
            handleError(e)
        }

        creating.value = false
    }

    async function fetchAreas(): Promise<void> {
        formData.value.address.area = null

        try {
            const resp = await axios.get<Area[]>('/api/nova-poshta/areas')
            areas.value = resp.data
        } catch (e) {
            handleError(e)
        }
    }

    async function fetchCities(): Promise<void> {
        if (!formData.value.address.area) {
            return
        }

        formData.value.address.city = null
        const uri = `/api/nova-poshta/cities/${formData.value.address.area.ref}`

        try {
            const resp = await axios.get<City[]>(uri)
            cities.value = resp.data
        } catch (e) {
            handleError(e)
        }
    }

    async function fetchWarehouses(): Promise<void> {
        if (!formData.value.address.city) {
            return
        }

        formData.value.address.warehouse = null
        const uri = `/api/nova-poshta/warehouses/${formData.value.address.city.ref}`

        try {
            const resp = await axios.get<Warehouse[]>(uri)
            warehouses.value = resp.data
        } catch (e) {
            handleError(e)
        }
    }

    return {
        formData,
        areas,
        cities,
        warehouses,
        creating,
        fetchAreas,
        fetchCities,
        fetchWarehouses,
        createOrder,
    }
})
