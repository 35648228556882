<script setup lang="ts">
import { computed } from 'vue'
import { useOptionStore } from '@/stores/option'
import linkTo from '@shared/modules/linkTo'

const optionStore = useOptionStore()

const logoURI = computed<string | null>(() => {
    const option = optionStore.options.find(option => option.name === 'logo-image')
    return option ? `/storage/options/${option.value}` : null
})
</script>

<template>
    <a
        :href="linkTo('/')"
        :class="[
            'transition-opacity hover:opacity-90 px-4 flex-shrink-0',
            'font-heading mr-4',
        ]"
    >
        <img
            v-if="logoURI"
            :src="logoURI"
            width="180"
            height="80"
            class="h-[32px] w-auto scale-[140%]"
        />

        <h1 v-else class="text-xl xl:text-2xl">VIP LENTA</h1>
    </a>
</template>
