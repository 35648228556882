<script setup lang="ts">
import type { Order } from '@shared/types/models'
import { trans } from 'laravel-vue-i18n'
import { ref, onMounted } from 'vue'
import axios from 'axios'
import handleError from '@/modules/handleError'
import Message from '@shared/components/Message.vue'
import Spinner from '@shared/components/Spinner.vue'
import StatusLine from '@/components/OrderStatus/StatusLine.vue'

type Props = {
    orderSlug: string
}

const props = defineProps<Props>()

const order = ref<Order | null>(null)
const loading = ref<boolean>(false)
const redirectingToPayment = ref<boolean>(false)

onMounted(process)

async function process(): Promise<void> {
    const payBtn = document.querySelector<HTMLInputElement>('._make-order-button')

    if (payBtn) {
        redirectingToPayment.value = true
        payBtn.click()
        return
    }

    redirectingToPayment.value = false
    await fetchOrder()
}

async function fetchOrder(): Promise<void> {
    loading.value = true

    try {
        const resp = await axios.get<Order>(`/api/orders/${props.orderSlug}`)
        order.value = resp.data

    } catch (e) {
        handleError(e)
    }

    loading.value = false
}
</script>

<template>
    <div class="absolute top-1/2 left-5 right-5 -translate-y-1/2">
        <spinner v-if="loading" />

        <message v-else-if="redirectingToPayment">
            {{ trans('main.redirecting_to_payment') }}
        </message>

        <message v-else-if="orderSlug === '' || !order">
            {{ trans('main.order_not_found') }}
        </message>

        <div v-else class="text-center">
            <h2 class="text-2xl md:text-4xl font-heading">
                {{ trans('main.your_order_is_number', { n: order.id.toString() }) }}
            </h2>

            <status-line
                v-if="order.status"
                :label="trans('main.order_status')"
                :value="order.status.name[$locale]"
            />
        </div>
    </div>
</template>
