<script setup lang="ts">
import { useSearchStore } from '@/stores/search'
import { useMenuStore } from '@/stores/menu'
import { useCategoryStore } from '@/stores/category'
import Logo from '@/components/Navbar/Logo.vue'
import NavMenu from '@/components/Navbar/NavMenu.vue'
import OpenCartButton from '@/components/Cart/OpenCartButton.vue'
import OpenSearchButton from '@/components/Navbar/Search/OpenSearchButton.vue'
import OpenMenuButton from '@/components/Navbar/Menu/OpenMenuButton.vue'
import WishlistButton from '@/components/Navbar/WishlistButton.vue'
import Search from '@/components/Navbar/Search/Search.vue'
import SearchSidebar from '@/components/Navbar/Search/SearchSidebar.vue'
import MobileMenu from '@/components/MobileMenu/MobileMenu.vue'
import Overlay from '@shared/components/Overlay.vue'
import SlideFromLeftTransition from '@shared/components/Transitions/SlideFromLeftTransition.vue'
import AppearTransition from '@shared/components/Transitions/AppearTransition.vue'
import CategoriesBar from '@/components/Navbar/CategoriesBar/CategoriesBar.vue'
import LanguageBar from '@/components/Navbar/LanguageBar/LanguageBar.vue'

const searchStore = useSearchStore()
const menuStore = useMenuStore()
const categoryStore = useCategoryStore()

function closeWindows(): void {
    searchStore.isOpen = false
    searchStore.results = []
    searchStore.query = ''
    menuStore.isOpen = false
    categoryStore.selectedCategory = null
}
</script>

<template>
    <div>
        <language-bar />

        <nav class="bg-nav text-white h-14">
            <div class="container">
                <div class="flex items-center justify-between gap-3 h-14">
                    <div class="flex items-center h-full">
                        <open-menu-button />
                        <logo />
                        <nav-menu />
                    </div>

                    <search class="hidden xl:block" :withOverlay="true" />

                    <ul class="flex items-center text-xl h-full">
                        <li class="h-full"><wishlist-button /></li>
                        <li class="h-full"><open-search-button /></li>
                        <li class="h-full"><open-cart-button /></li>
                    </ul>
                </div>
            </div>
        </nav>

        <slide-from-left-transition>
            <mobile-menu v-if="menuStore.isOpen" />
        </slide-from-left-transition>

        <slide-from-left-transition>
            <search-sidebar v-if="searchStore.isOpen" />
        </slide-from-left-transition>

        <appear-transition>
            <overlay
                v-if="searchStore.isOpen || menuStore.isOpen"
                @closed="closeWindows"
            />
        </appear-transition>

        <categories-bar></categories-bar>
    </div>
</template>
