<script setup lang="ts">
import { ref, computed, watchEffect } from 'vue'
import LabelElem from '@shared/components/Form/LabelElem.vue'
import showToast from '@shared/modules/showToast'
import { trans } from 'laravel-vue-i18n'

const emit = defineEmits<{
    (e: 'change'): void
}>()

const props = defineProps<{
    label: string
    id: string
    name?: string
    required?: boolean
    placeholder?: string
    class?: string
    tip?: string
    disabled?: boolean
    items: object[]
    itemKey: string
    itemLabel: string
}>()

const modelValue = defineModel()
const query = ref<string>('')
const placedCursor = ref<boolean>(false)

watchEffect(() => {
    if (!modelValue.value) {
        query.value = ''
    }
})

const showDropdown = computed<boolean>(() => {
    return !props.disabled && placedCursor.value
})

const filteredItems = computed<object[]>(() => {
    const items = props.items.filter(item => {
        return item[props.itemLabel]
            .toLowerCase()
            .includes(query.value.toLowerCase())
    })

    // if item name starts with query.value, show it first
    items.sort((a, b) => {
        const q = query.value.toLowerCase()

        if (a[props.itemLabel].toLowerCase().startsWith(q)) {
            return -1
        }

        if (b[props.itemLabel].toLowerCase().startsWith(q)) {
            return 1
        }

        return 0
    })

    return items
})

function selectItem(item: object): void {
    placedCursor.value = false
    query.value = item[props.itemLabel]
    modelValue.value = item

    emit('change')
}

function blur(): void {
    setTimeout(() => {
        placedCursor.value = false

        if (query.value === '' || !hasSuchItem()) {
            clear()
        } else {
            selectFirstItem()
        }
    }, 300)
}

function hasSuchItem(): boolean {
    return props.items.some(item => {
        return item[props.itemLabel]
            .toLowerCase()
            .includes(query.value.toLowerCase())
    })
}

function clear(): void {
    query.value = ''
    modelValue.value = null
    emit('change')
    showToast({ text: trans('main.choose_from_existing'), success: false })
}

function selectFirstItem(): void {
    if (filteredItems.value.length) {
        selectItem(filteredItems.value[0])
    }
}
</script>

<template>
    <div class="flex flex-col relative">
        <label-elem :id :tip :label />

        <input
            class="bg-page border border-border rounded-sm w-full h-12 px-3 max-w-96 font-sans"
            autocomplete="off"
            v-model="query"
            @change="emit('change')"
            @focus="placedCursor = true"
            @blur="blur"
            @keydown.enter.prevent="selectFirstItem"
            :class
            :id
            :name
            :placeholder="disabled ? '' : placeholder"
            :required
        />

        <div
            v-if="showDropdown"
            :class="[
                'absolute bg-page top-[75px] border border-border z-10 w-full',
                'rounded-b-md shadow-md divide-y divide-border max-h-96 overflow-y-auto max-w-96',
            ]"
        >
            <button
                v-for="item in filteredItems"
                :key="item[itemKey]"
                @click="selectItem(item)"
                class="py-1 px-2 hover:bg-page-second block w-full text-left"
            >
                {{ item[itemLabel] }}
            </button>
        </div>
    </div>
</template>
