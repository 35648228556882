import type { Option } from '@shared/types/models'
import type { SiteOptionName } from '@shared/types'
import { ref, onMounted } from 'vue'
import handleError from '@/modules/handleError'
import { defineStore } from 'pinia'
import axios from 'axios'

export const useOptionStore = defineStore('option', () => {
    const options = ref<Option[]>([])
    const loading = ref<boolean>(true)

    onMounted(fetchOptions)

    async function fetchOptions(): Promise<void> {
        loading.value = true

        try {
            const resp = await axios.get<Option[]>(`/api/options`)

            if (resp.data) {
                options.value = resp.data
            }
        } catch (e) {
            handleError(e)
        }

        loading.value = false
    }

    function getOption(name: SiteOptionName): Option | null {
        const match = options.value.find(option => option.name === name)

        if (!match) {
            console.warn(
                `Option ${name} not found. Don't forget to fetch them first`,
            )
            return null
        }

        return match
    }

    return {
        options,
        loading,
        getOption,
    }
})
