<script setup lang="ts">
import type { Order, PaymentStatus } from '@shared/types/models'
import { trans } from 'laravel-vue-i18n'
import { ref, onMounted } from 'vue'
import axios from 'axios'
import handleError from '@/modules/handleError'
import Spinner from '@shared/components/Spinner.vue'
import StatusLine from '@/components/OrderStatus/StatusLine.vue'
import { FINAL_STATUSES, BAD_STATUSES } from '@shared/static'

type Props = {
    order: Order
}

const props = defineProps<Props>()
const loading = ref<boolean>(false)
const status = ref<PaymentStatus | null>(props.order.payment!.last_status)
const interval = ref<number | null>(null)

onMounted(watchPaymentStatus)

async function watchPaymentStatus(): Promise<void> {
    interval.value = setInterval(fetchPaymentStatus, 5000)

    try {
        await fetchPaymentStatus()
    } catch (e) {
        handleError(e)
        clearFetchingInterval()
    }
}

async function fetchPaymentStatus(): Promise<void> {
    if (loading.value) {
        return
    }

    loading.value = true

    try {
        const uri = `/api/payments/status/${props.order.payment!.id}`
        const resp = await axios.get<PaymentStatus | null>(uri)
        console.info('Fetched payment status at ' + Date.now())
        status.value = resp.data

        if (resp.data && FINAL_STATUSES.includes(resp.data) && interval.value) {
            clearFetchingInterval()
        }
    } catch (e) {
        handleError(e)
    }

    loading.value = false
}

function clearFetchingInterval(): void {
    if (interval.value) {
        clearInterval(interval.value)
        console.info('Cleared fetching interval...')
    }
}
</script>

<template>
    <div class="absolute top-1/2 left-5 right-5 -translate-y-1/2">
        <div class="text-center">
            <h1 class="text-xl md:text-2xl">
                {{ trans('payment.thanks_for_your_order') }}!
            </h1>

            <h2 class="text-2xl md:text-4xl font-heading">
                {{ trans('main.your_order_is_number', { n: order.id.toString() }) }}
            </h2>

            <status-line
                v-if="status"
                :label="trans('payment.payment_status')"
                :value="trans(`payment.status.${status}`)"
                :color="BAD_STATUSES.includes(status) ? 'text-red-700' : null"
            />

            <spinner
                v-if="status && !FINAL_STATUSES.includes(status)"
                class="mt-4"
            />
        </div>
    </div>
</template>
