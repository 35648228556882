<script setup lang="ts">
import { ref } from 'vue'
import { trans } from 'laravel-vue-i18n'
import axios from 'axios'
import handleError from '@/modules/handleError'
import InputField from '@shared/components/Form/InputField.vue'
import TextareaField from '@shared/components/Form/TextareaField.vue'
import BigButton from '@shared/components/Buttons/BigButton.vue'
import Checkbox from '@shared/components/Form/Checkbox.vue'

const agreeToTerms = ref<boolean>(false)
const loading = ref<boolean>(false)
const successMessage = ref<string | null>(null)
const formData = ref({
    name: '',
    email: '',
    message: '',
})

async function sendMessage(): Promise<void> {
    if (!agreeToTerms.value) {
        return
    }

    loading.value = true

    try {
        await axios.post('/api/feedback', formData.value)

        formData.value.name = ''
        formData.value.email = ''
        formData.value.message = ''
        agreeToTerms.value = false

        successMessage.value = 'main.your_message_has_been_sent'
    } catch (e) {
        handleError(e)
    }

    loading.value = false
}
</script>

<template>
    <h2 v-if="successMessage" class="text-2xl text-green-800 mt-4">
        {{ trans(successMessage) }}
    </h2>

    <form v-else @submit.prevent="sendMessage" class="mt-8 space-y-5">
        <input-field
            v-model="formData.name"
            :label="trans('main.your_name')"
            :placeholder="trans('main.your_name')"
            class="max-w-full"
            id="name"
            required
        />

        <input-field
            v-model="formData.email"
            type="email"
            :label="trans('main.your_email_for_connection')"
            :placeholder="trans('main.your_email_for_connection')"
            class="max-w-full"
            id="email"
            required
        />

        <textarea-field
            v-model="formData.message"
            :label="trans('main.your_message')"
            :placeholder="trans('main.your_message')"
            id="message"
            required
        />

        <checkbox v-model="agreeToTerms" id="agree">
            {{ trans('main.ive_read') }}

            <a href="/pages/terms" class="text-link hover:underline">
                {{ trans('main.guarantees') }}
            </a>

            {{ trans('main.and_agree_to_terms') }}
        </checkbox>

        <div class="flex gap-4">
            <big-button type="submit" :loading :disabled="!agreeToTerms">
                {{ trans('main.send') }}
            </big-button>

            <big-button
                v-if="$auth"
                href="/admin/feedback"
                class="!bg-transparent border border-border !text-black hover:border-black"
            >
                Читать сообщения
            </big-button>
        </div>
    </form>
</template>
