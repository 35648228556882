<script setup lang="ts">
import type { Product, Attachment } from '@shared/types/models'
import { computed, ref } from 'vue'
import { useImageSwitcherStore } from '@/stores/imageSwitcher'
import Arrows from '@/components/Products/ImageSwitcher/Arrows.vue'
import InnerImageZoom from 'vue-inner-image-zoom'
import HeartIcon from '@shared/components/Icons/HeartIcon.vue'

const props = defineProps<{
    product: Product
}>()

const store = useImageSwitcherStore(props.product)()
const isActiveZoom = ref<boolean>(false)
const swipeNext = ref<boolean>(false)
const isZoomed = ref<boolean>(false)

const touchPos = ref({
    start: 0,
    end: 0,
})

const clickPos = ref({
    start: 0,
    end: 0,
})

const images = computed<Attachment[]>(() => {
    if (!props.product.attachments) {
        return []
    }

    return props.product.attachments.filter(a => a.type === 'image')
})

function handleEndTouch(e: Event): void {
    touchPos.value.end = (e as TouchEvent).changedTouches[0].clientX

    if (touchPos.value.start - touchPos.value.end > 60) {
        swipeToNext()
    } else if (touchPos.value.start - touchPos.value.end < -60) {
        swipeToPrev()
    }
}

function swipeToNext(): void {
    if (isZoomed.value) {
        return
    }

    isActiveZoom.value = false
    swipeNext.value = false
    store.nextImage()
}

function swipeToPrev(): void {
    if (isZoomed.value) {
        return
    }

    isActiveZoom.value = false
    swipeNext.value = true
    store.prevImage()
}
</script>

<template>
    <div
        class="relative"
        :class="
            store.selectedImage && store.selectedImage.type === 'video'
                ? ''
                : 'md:overflow-hidden product-image-height'
        "
        @touchstart="touchPos.start = $event.touches[0].clientX"
        @mouseup="clickPos.end = $event.clientX"
        @mousedown="clickPos.start = $event.clientX"
        @touchend="handleEndTouch"
    >
        <div
            v-if="product.likes > 0"
            class="absolute right-0 bottom-16 bg-gray-800 z-10 flex gap-1.5 items-center py-0.5 px-5 rounded-l-full"
        >
            <span class="text-white">{{ product.likes }}</span>
            <heart-icon class="w-5 h-5 text-white" fill="white" />
        </div>

        <div v-if="store.selectedImage && store.selectedImage.type === 'image'">
            <div
                v-for="img in images"
                :key="img.id"
                class="relative md:max-w-[544px]"
            >
                <div class="flex">
                    <transition
                        :enter-from-class="
                            'opacity-0 ' + (swipeNext ? 'right-96' : 'left-96')
                        "
                        :leave-from-class="swipeNext ? 'right-0' : 'left-0'"
                        :leave-to-class="
                            'opacity-0 ' + (swipeNext ? '-right-96' : '-left-96')
                        "
                        :enter-to-class="swipeNext ? 'right-0' : 'left-0'"
                        enter-active-class="absolute z-10 transition-all duration-300"
                        leave-active-class="absolute transition-all duration-300"
                    >
                        <inner-image-zoom
                            v-if="store.selectedImage?.id === img.id"
                            :src="`/storage/products/xl/${store.selectedImage.name}`"
                            :zoomSrc="`/storage/products/original/${store.selectedImage.name}`"
                            :className="
                                `rounded-sm select-none touch-pan-y product-image-height ` +
                                (product.status === 'hidden'
                                    ? 'filter grayscale'
                                    : '')
                            "
                            :alt="`Product image ${product.name[$locale]}`"
                            :afterZoomIn="() => (isZoomed = true)"
                            :afterZoomOut="() => (isZoomed = false)"
                            :hideHint="true"
                            :zoomPreload="true"
                        />
                    </transition>
                </div>
            </div>
        </div>

        <video
            v-else-if="store.selectedImage && store.selectedImage.type === 'video'"
            controls
            autoplay
            loop
        >
            <source
                :src="`/storage/products/videos/${store.selectedImage.name}`"
                type="video/mp4"
            />
        </video>

        <img
            v-else
            src="/storage/products/xl/default.webp"
            alt="Product Image"
            width="512"
            class="rounded-sm select-none"
            draggable="false"
        />

        <arrows
            v-if="images.length > 1"
            :product
            @prev="swipeToPrev"
            @next="swipeToNext"
        />
    </div>

    <!-- Navigation dots -->
    <div
        v-if="images.length > 1"
        class="flex md:hidden gap-5 absolute left-1/2 -translate-x-1/2 bottom-10 z-20"
    >
        <div
            v-for="img in images"
            :key="img.id"
            @click="store.selectedImage = img"
            class="w-3 h-3 rounded-full border border-border"
            :class="{
                'bg-black/70': store.selectedImage?.id === img.id,
                'bg-white/70': store.selectedImage?.id !== img.id,
            }"
        ></div>
    </div>
</template>
